body
{
	padding: 0;
	margin: 0;
	overflow-y: scroll;
	font-size: 16px;

	:global(.r-medium) &
	{
		font-size: 14px;
	}

	:global(.r-small) &
	{
		font-size: 13px;
	}

	a
	{
		color: #3090FF;
	}
}

